body{
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

input{
    width: 70vw;
    padding: 10px;
    margin-bottom: 20px;
}

button{
    margin: 0px 10px;
    color: white;
    background-color: rgb(70, 0, 69);
    padding: 15px;
}

button {
    display: inline-block;
    padding: 10px 20px;
    font-size: 16px;
    color: #fff;
    background-color: #222;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
button:hover {
    background-color: #333;
}
  
button:active {
    background-color: #111;
    box-shadow: none;
}
  
button:focus {
    outline: none;
}