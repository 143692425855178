.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.header {
  display: flex;
  align-items: center;
  background-color: #f1f1f1;
  padding: 20px;
}

.logo {
  margin-left: 20px;
  font-size: 24px;
  font-weight: bold;
  color: #222;
}

.search-form {
  display: flex;
  align-items: flex-start;
  margin-left: 20px;
}

.search-form input {
  width: 30vw;
  padding: 11px;
  margin: 0px;
  border: none;
  outline: none;
}

.search-form button {
  padding: 10px;
  background-color: #222;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

.content {
  flex: 1;
  padding: 20px;
}

.footer {
  background-color: #f1f1f1;
  color: #222;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

.copyright {
  font-size: 14px;
}

.links {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
}

.links li {
  margin-left: 20px;
}

.links a {
  color: #222;
  text-decoration: none;
}

.links a:hover {
  color: #f1f1f1;
}

img {
  width: 100vw;
}

@media only screen and (max-width: 600px){
/*Big smartphones [426px -> 600px]*/
  .header {
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    padding: 20px;
  }
  .logo-row{
    margin-bottom: 20px;
  }
}